<template>
  <div>
    <v-container fluid>
      <breadcrumbs :breadcrumbs="breadcrumbs"/>

      <v-row>

        <v-col cols="3">
          <BackofficeNavigation class="sticky-block"/>
        </v-col>

        <v-col cols="9">

          <v-row v-if="isLoading">
            <v-col cols="6" v-for="el in 4" :key="el">
              <v-card class="rounded-lg shadow">
                <v-card-text>
                  <v-skeleton-loader type="article,actions"/>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-if="!isLoading && investigations.length">

            <v-col cols="6" v-for="(item,i) in investigations" :key="i">
              <div class="translateY">
                <v-card class="rounded-lg shadow" :to="'/backoffice/final-validation/'+item.investigation.id">

                  <v-card-title>
                    {{ $func.setName(item.investigation.name, item.investigation.campaign.lang) }}
                    <v-spacer/>

                    <v-btn fab depressed small :to="'/backoffice/final-validation/'+item.investigation.id">
                      <v-icon>mdi-format-list-checks</v-icon>
                    </v-btn>

                  </v-card-title>

                  <v-card-subtitle>
                    CAMPAIGNE : {{ $func.setName(item.investigation.campaign.name, item.investigation.campaign.lang) }}

                    <v-spacer class="py-1"/>

                    <strong class="blue--text">
                      MONTANT :
                      {{ item.investigation.amount | toCurrency }}
                    </strong>

                  </v-card-subtitle>

                  <v-card-text>

                    <v-list-item two-line>
                      <v-list-item-avatar rounded class="bg-snipper">
                        <v-icon color="blue">mdi-clipboard-text-outline</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.total }}</v-list-item-title>
                        <v-list-item-subtitle>Total</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider/>

                    <v-list-item two-line>
                      <v-list-item-avatar rounded class="bg-snipper">
                        <v-icon color="success">mdi-check-circle</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Réponses validées</v-list-item-title>
                        <v-list-item-subtitle>Total : {{ item.totalValidated }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="text-right">
                          <strong>{{ item.percentValidated }}%</strong>
                          <v-progress-linear
                              rounded
                              color="blue"
                              height="5"
                              :value="item.percentValidated"
                              class="w-100"
                              striped
                          ></v-progress-linear>
                        </div>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider/>

                    <v-list-item two-line>
                      <v-list-item-avatar rounded class="bg-snipper">
                        <v-icon color="error">mdi-close-circle</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Réponses réfusées</v-list-item-title>
                        <v-list-item-subtitle>Total : {{ item.totalRefused }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="text-right">
                          <strong>{{ item.percentRefused }}%</strong>
                          <v-progress-linear
                              rounded
                              color="blue"
                              height="5"
                              :value="item.percentRefused"
                              class="w-100"
                              striped
                          ></v-progress-linear>
                        </div>
                      </v-list-item-action>
                    </v-list-item>

                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>


          <v-card v-if="!isLoading && !investigations.length"
                  class="rounded-lg shadow">
            <v-card-text>
              <NoResult size="200"/>
            </v-card-text>
          </v-card>

        </v-col>

      </v-row>


    </v-container>
  </div>
</template>

<script>

import BackofficeNavigation from "@/views/backoffice/components/BackofficeNavigation";
import {HTTP} from "@/http-common";

export default {
  components: {BackofficeNavigation},
  data() {
    return {
      isLoading: false,
      investigations: [],
    }
  },
  methods: {
    getTemporaryInvestigations() {
      this.$Progress.start()
      this.isLoading = true
      HTTP.get('/backoffice/investigations/temporary').then(res => {
        this.investigations = res.data.data
        this.$Progress.finish()
        this.isLoading = false
      }).catch(err => {
        this.$Progress.fail()
        this.$errorMessage = this.$i18n.t('message.catchError')
        this.isLoading = false
        console.log(err)
      })
    },
  },
  created() {
    this.getTemporaryInvestigations()
  },
  computed: {
    breadcrumbs() {
      return [
        {to: '/#', text: 'breadcrumb.backoffice', disabled: true},
        {to: '/#', text: 'backoffice.finalValidation', disabled: true},
      ]
    },
  }
}
</script>

<style scoped>
</style>